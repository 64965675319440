import React from "react"
import BlogApi from "../graphql/blog"
import { Layout, SEO, Container, LinkHandler, BlogFeature } from "src/sws-ui"
import "src/css/pages/_not-found.scss"
import "src/css/pages/_news.scss"
import group12 from "src/images/icons/Group12.png"
import group121 from "src/images/icons/Group121.png"

import translate from "src/helpers/language"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  const BlogApidata1 = BlogApi()
  var BlogApidata = BlogApidata1.allMarkdownRemark.nodes.filter(
    data => data.frontmatter.category_language === "English"
  )

  const most_recent_blog = BlogApidata.length > 0 && BlogApidata[0]
  return (
    <Layout>
      <SEO title="404 Page Not Found" />
      <div
        className={"container-fluid"}
        style={{
          width: "100%",
        }}
      >
        <div className="background__images">
          <img src={group12}></img>
          <img src={group121}></img>
        </div>
        <div className="container-fluid">
          <div className={"row"}>
            <div className={"col-md-2"}></div>
            <div className="col-md-10">
              <h2>
                <h1 className="">404</h1>Oops! The page was not found.
              </h2>
              <p>
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
              </p>

              <LinkHandler
                className="accordion__link"
                linkUrl={
                  typeof window !== "undefined" &&
                  window.location.href.includes("/th")
                    ? "/th/"
                    : "/"
                }
                arrow={true}
              >
                {translate("Homepage", "หน้าแรก")}
              </LinkHandler>
            </div>
            <div className={"col-md-2"}></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className={"col-md-2"}></div>
          {BlogApidata.slice(0, 3).map((data, counter) => {
            return (
              <div className={"col-md-3"}>
                <BlogFeature
                  heading={data.frontmatter.title}
                  content={data.frontmatter.summary}
                  category={data.frontmatter.category}
                  image={
                    data.frontmatter.image.split("/")[
                      data.frontmatter.image.split("/").length - 1
                    ]
                  }
                  detailpagelink={
                    "/blogs/" + data.frontmatter.title.replace(/\s/g, "_")
                  }
                ></BlogFeature>
                <div style={{ gridColumnEnd: "span 1" }}></div>
              </div>
            )
          })}
          <div className={"col-md-2"}></div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
